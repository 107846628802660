<template>
  <div>
    <van-cell-group title="流程">
      <van-cell title="新增申请" is-link size="large" icon="records" to="/scrap/edit" />
      <van-cell title="我的审批" is-link size="large" icon="todo-list-o" to="/scrap/list/sp" />
    </van-cell-group>
    <van-cell-group title="查询">
      <van-cell title="我的记录" is-link size="large" icon="records" to="/scrap/list/mine" />
      <van-cell v-if="whale.user.hasPower('/loss/power')" title="全部记录" is-link size="large" icon="todo-list-o" to="/scrap/list/all" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped></style>